<template>
    <component is="b-card">

        <!-- Media -->
    <b-media class="mb-2">
        <template #aside>
            <b-avatar
            ref="previewEl"
            :src="userData.photo"
            :text="avatarText(userData.nom)"
            variant="light-success"
            size="90px"
            rounded
            />
        </template>
        <h4 class="mb-1"> {{ userData.nom }} </h4>
        <div class="d-flex flex-wrap">
            <b-button variant="primary" @click="$refs.refInputEl.click()" >

                <input ref="refInputEl"  type="file"  variant="primary" class="d-none" @input="onFileChange" >
                <span class="d-none d-sm-inline">Ajouter photo profile</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
            </b-button>
            
        </div>
    </b-media>

    <validation-observer ref="simpleRules">
    <b-form @submit.prevent=""
            @reset.prevent="resetForm" >
        <div class="d-flex">
            <feather-icon icon="UserIcon" size="19" />
            <h4 class="mb-0 ml-50">Information Personnel</h4>
        </div>
        <b-row class="mt-1">

            <b-col cols="12" md="4">
                <b-form-group label="Nom" label-for="nom" >
                    <validation-provider #default="{ errors }" name="nom" rules="required">
                        <b-form-input 
                            id="nom" 
                            v-model="userData.nom"  
                            :state="errors.length > 0 ? false:null"  
                            autofocus
                            trim 
                            placeholder="Nom"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
                <b-form-group label="Nom personnel" label-for="nomP" >
                    <validation-provider #default="{ errors }" name="nomP" rules="required">
                        <b-form-input 
                            id="nomP" 
                            v-model="userData.nom_personnel"  
                            :state="errors.length > 0 ? false:null"  
                            autofocus
                            trim 
                            placeholder="Nom personnel"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
                <b-form-group label="Prenom personnel" label-for="prenomP" >
                    <validation-provider #default="{ errors }" name="prenomP" rules="required">
                        <b-form-input 
                            id="prenomP" 
                            v-model="userData.prenom_personnel"  
                            :state="errors.length > 0 ? false:null"  
                            autofocus
                            trim 
                            placeholder="Prenom personnel"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
                
                <b-form-group label="Tel" label-for="tel" >
                    <validation-provider #default="{ errors }" name="tel" rules="required">
                        <b-form-input id="tel" 
                            v-model="userData.tel" 
                            type="number" 
                            :state="errors.length > 0 ? false:null"
                            autofocus
                            trim 
                            placeholder="Téléphone"   />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
                <b-form-group label="Email" label-for="email" >
                    <validation-provider #default="{ errors }" name="email" rules="required">
                    <b-form-input id="email" v-model="userData.email" type="email" 
                            :state="errors.length > 0 ? false:null"
                            autofocus
                            trim 
                            placeholder="Email"   />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row class="mt-1">

            <b-col cols="12" md="4">
                <b-form-group label="Horaires" label-for="horaire" >
                    <validation-provider #default="{ errors }" name="horaire" rules="required">
                        <b-form-input id="horaire" v-model="userData.horaires_text" 
                            :state="errors.length > 0 ? false:null"
                            autofocus
                            trim 
                            placeholder="Intervention 24h/24"   />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
                <b-form-group label="Abonnement" label-for="abonnement">
                    <validation-provider #default="{ errors }" name="Abonnement" >
                        <v-select
                            v-model="userData.abonnement_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="abonnementChoices"
                            label="name"
                            :reduce="abonnement => abonnement.id"
                            input-id="abonnement"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>

            <b-col cols="12" md="4">
                <b-form-group label="Service" label-for="service">
                    <v-select
                        v-model="userData.services"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="serviceChoices"
                        label="name"
                        multiple
                        :reduce="service => service.id"
                        input-id="service"
                    />
                </b-form-group>
            </b-col>

           
        </b-row>

        <b-row class="mt-1">

            <b-col cols="12" md="4">
                <b-form-group label="Expérience" label-for="year_experience" >
                     <validation-provider #default="{ errors }" name="year_experience" rules="required">
                        <b-form-input 
                            id="year_experience" 
                            v-model="userData.year_experience"  
                            :state="errors.length > 0 ? false:null"  
                            autofocus
                            trim 
                            placeholder="4 ans"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
            </b-col>
          
             <b-col cols="4" md="2" >
                <b-form-group
                    label="Urgence répondre"
                    label-for="urgence"
                    label-class="mb-1" >
                    <b-form-radio-group id="urgence"
                        v-model="userData.urgence_repond"
                        :checked="userData.urgence_repond"
                        :options="urgenceOptions"
                        name="urgence"
                    />
                </b-form-group>
            </b-col> 

            <b-col cols="4" md="2">
                <b-form-group
                    label="Actif"
                    label-for="actif"
                    label-class="mb-1" >
                    <b-form-radio-group id="actif"
                        v-model="userData.actif"
                        :options="actifOptions"
                    />
                </b-form-group>
            </b-col>

            <b-col cols="4" md="2">
                <b-form-group
                    label="Afficher sur site"
                    label-for="show_in_site"
                    label-class="mb-1" >
                    <b-form-radio-group id="show_in_site"
                        v-model="userData.show_in_site"
                        :options=" [{ text: 'Oui', value: 1 },{ text: 'Non', value: 0 }]"
                    />
                </b-form-group>
            </b-col>
        
        </b-row>
        
        <b-row class="mt-1">
            <b-col cols="12">
                <b-form-group label="Description" label-for="description" class="mb-2" >
                    <quill-editor
                    id="description"
                    v-model="userData.description"
                    :options="snowOption"
                    />
                </b-form-group>
            </b-col>
        </b-row>


        <div class="d-flex mt-2">
            <feather-icon icon="MapPinIcon" size="19" />
            <h4 class="mb-0 ml-50"> Adresse </h4>
        </div>

        <b-row class="mt-1">

            <b-col cols="12" md="4">
                <b-form-group label="Adresse" label-for="address" >
                    <b-form-input id="address" v-model="userData.address" />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group label="Code postal" label-for="zipcode" >
                    <b-form-input id="zipcode" v-model="userData.zipcode" />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group label="Ville" label-for="ville" >
                    <b-form-input id="ville" v-model="userData.ville" />
                </b-form-group>
            </b-col>
            
            <b-col cols="12" md="4">
                <b-form-group label="Région" label-for="region" >
                    <validation-provider #default="{ errors }" name="region" >
                        <b-form-input id="region" v-model="userData.region_text" 
                            :state="errors.length > 0 ? false:null"
                            autofocus
                            trim 
                            placeholder="Intervient sur tout le ...."   />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

          
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group label="Latitude" label-for="lat" >
                    <b-form-input id="lat" v-model="userData.map_lat" />
                </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
                <b-form-group label="longtitude" label-for="long" >
                    <b-form-input id="long" v-model="userData.map_long" />
                </b-form-group>
            </b-col>

            
        
        </b-row>

        
        <div class="d-flex mt-2">
            <feather-icon icon="CameraIcon" size="19" />
            <h4 class="mb-0 ml-50">Travaux réalisés</h4>
        </div>
        <b-row>
            <b-col md="6">
                <b-card>
                    <h5 class="mb-0"></h5>
                    <hr/>
                    <b-row>
                        <b-col  md="2" cols="12" class="profile-latest-img" v-for="(photo, index) in userData.photos">
                            <b-media class="mb-2">
                                <template #aside>
                                    <b-avatar
                                    ref="travaux"
                                    :src="photo.src"
                                    variant="light-success"
                                    size="90px"
                                    rounded
                                    />
                                </template>
                            
                                <feather-icon
                                    icon="XCircleIcon"
                                    class="d-inline"
                                    @click="removeFile(index,photo.id)"
                                />
                            </b-media>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <div class="d-flex flex-wrap">
                                <b-button variant="primary" @click="$refs.refInputTrav.click()" >
                                    <input ref="refInputTrav"  type="file" multiple variant="primary" class="d-none" @input="onFileTravaux" >
                                    <span class="d-none d-sm-inline">Upload travaux</span>
                                    <feather-icon
                                        icon="EditIcon"
                                        class="d-inline d-sm-none"
                                    />
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card> 
            </b-col>
        </b-row>

        <b-row class="mt-2">
            <b-col>
                <b-button variant="primary" type="submit" class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" @click="enregistrer">
                    Enregistrer
                </b-button>
              
            </b-col>
        </b-row>
    </b-form>
    </validation-observer>


    </component>
</template>


<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput,BFormRadioGroup, BFormCheckboxGroup, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapActions, mapGetters } from 'vuex'

export default {
    components: {
        BButton,  BMedia,  BAvatar,  BRow,  BCol, BFormGroup, BFormInput, BFormCheckboxGroup, BForm, BTable, BCard, BCardHeader, BCardTitle, 
        BFormCheckbox, BFormRadioGroup, vSelect, quillEditor, 
        ValidationProvider,
        ValidationObserver,
        ToastificationContent,
    },

    data() {
        return {

            userData: {
                nom: null,
                nom_personnel: null,
                prenom_personnel: null,
                tel: null,
                email: null,
                photo: null,
                solde: null,
                solde_free_contact: null,
                description: null,
                address: null,
                zipcode: null,
                map_lat: null,
                map_long: null,
                region_text: null,
                horaires_text: 'Intervention 24h/24',
                urgence_repond: 1,
                ville: null,
                abonnement_id: null,
                abonnement: null,
                actif: 1,
                services: null,
                photos: [],
                show_in_site: 1,
                year_experience: '.. ans'
            },

            photo:{
                id: null,
                src: null,
            },

            avatarText,

            urgenceOptions : [
                { text: 'Oui', value: 1 },
                { text: 'Non', value: 0 },
            ],

            actifOptions : [
                { text: 'Oui', value: 1 },
                { text: 'Non', value: 0 },
            ],

            snowOption: {
                theme: 'snow',
            },

            inputImageRenderer:null,

      }
    },

    computed: {
        ...mapGetters('abonnements', {'abonnementChoices' : 'GET_ITEMS'}),
        ...mapGetters('services', {'serviceChoices' : 'GET_ITEMS'}),

    },
    methods: {
        ...mapActions('artisans', {'addItem':'addItem'}),
        ...mapActions('abonnements', {'fetchAbonnements' : 'fetchItems'}),
        ...mapActions('services', {'fetchServices' : 'fetchItems'}),

        notification(variant, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                title: title,
                icon: variant == 'danger' ? 'XCircleIcon' : 'CheckCircleIcon',
                text: text,
                variant
                },
            })
        },

        enregistrer() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.addItem(this.userData).then(() => {
                          this.notification('success',  'L\'ajout', 'L\'artisan est bien ajouté')
                        this.$router.push(`/artisans`);
                    })  
                }else{
                    this.notification('danger',  'L\'ajout', 'L\'artisan n\'est ajouté. Verifier les informations')
                }
            })
        },

         onFileTravaux(e){
            const tmpFiles = e.target.files
            if (tmpFiles.length === 0) {
                return false;
            }
            const self = this
            for(var f in tmpFiles){
                const file = tmpFiles[f]
                const reader = new FileReader()
                reader.onload = function(e) {
                    self.photo = {
                        id: null,
                        src: e.target.result
                    }
                    self.userData.photos.push(self.photo)
                }
                reader.readAsDataURL(file)
            }


        },

        removeFile: function(index,id) {
            this.userData.photos.splice(index, 1);
        },

        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0]);
        },

        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                vm.userData.photo = e.target.result;
            };
            reader.readAsDataURL(file);
        }
  
    },
    
    mounted(){  
        this.$refs.simpleRules.reset()
    },

    created() {
        this.fetchAbonnements()
        this.fetchServices()
    },

    watch: {
    }

}
</script>

<style>
    .v-select:not(.vs--single) .vs__deselect svg {
        fill: #fff;
    }

    .profile-latest-img .media .media-body{
        position: relative;
        left: -30px;
        cursor: pointer;
    }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
